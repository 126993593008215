const loadPageView = () => {
  const view = `
    <div class="loadpage__wrapper">
      <div class="loadpage__text">
        <p class="loadpage__phrase">Me gusta comer.</p>
      </div>
    </div>
  `
  return view
}

export default loadPageView()
